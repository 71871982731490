.main {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  height: 100vh;
}

.heart {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.heart>svg {
  width : 40px;
  height: 40px;

  animation: heartBeat 3s ease-in-out infinite;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}