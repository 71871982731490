.main {
  width  : 100vw;
  height : 100vh;

  display        : flex;
  flex-direction : column;

  overflow: hidden;

  padding: 10px 20px;

  background-color: #F0F0F0;

  font-family: 'Microsoft Sans Serif' !important;

  -webkit-user-select: none;
  /* Safari */
  -ms-user-select    : none;
  /* IE 10 and IE 11 */
  user-select        : none;
  /* Standard syntax */
}

/* Header */

.header {
  width : 100%;
  height: fit-content;

  display       : flex;
  flex-direction: row;
  column-gap    : 20px;
}

.headerInformation {
  width: 450px;
}

.headerTable {
  display        : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : center;
  column-gap     : 20px;
  margin-top     : 4px;
}

.headerFinish {
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : center;
  column-gap     : 20px;
}

.headerTableKey {
  text-align: right;
}

.headerTableValue {
  font-weight : 800;
  padding-left: 10px;
  color       : rgb(0, 0, 165);
}

.headerTimeFlag {
  color      : rgb(0, 0, 165);
  font-weight: bolder;
  font-size  : 50px;
}

.headerFontSize {
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : center;
  column-gap     : 10px;
  margin-top     : 4px;
}

.headerFontSize input {
  font-size: 12px;
}

.headerSize>input {
  width: 50px;
}

/* Body */

.tempBody {
  border: 1px solid rgb(226, 226, 226);
  height: 70%;
}

.body {
  border        : 3px solid rgb(255, 255, 255);
  display       : flex;
  flex-direction: row;
  height        : 100%;
}

.bodyAnswer {
  width : 90px;
  height: 100%;

  padding-top: 45px;

  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.bodyAnswerTitle {
  font-size  : 16px;
  font-weight: bold;
  color      : rgb(0, 0, 165);

  padding-bottom: 10px;
}

.bodyAnswerItem {
  display       : flex;
  flex-direction: row;
  column-gap    : 10px;
}

.bodyAnswerTable td {
  padding: 6px 2px;
}

.bodyAnswerLabel {
  font-size: 12px;

  display       : flex;
  flex-direction: row;
  align-items   : center;
}

.bodyAnswerCheckbox {
  display        : flex;
  flex-direction : row;
  align-items    : center;
  justify-content: center;
}

.bodyAnswerbuttonNext {
  margin-top: 20px;
}

.bodyQuestion {
  width : 100%;
  height: 100%;

  display       : flex;
  flex-direction: column;
}

.bodyQuestionNumber {
  font-size  : 16px;
  font-weight: bold;
  color      : rgb(0, 0, 165);

  padding-top: 16px;

  height: 50px;
}

.bodyQuestionContent {
  width : 100%;
  height: 100%;

  margin-bottom   : 10px;
  background-color: white;

  border: 2px inset rgb(226, 226, 226);

  overflow-y: auto;
  padding   : 4px 10px;

  white-space: pre-line;
}

.footer {
  padding: 12px;
}

.footerButtons {
  display   : flex;
  flex-wrap : wrap;
  width     : 100%;
  column-gap: 2px;
  row-gap   : 2px;
}

.footerButtons button {
  border          : 1px solid rgb(197, 197, 197);
  padding-top     : 0px;
  padding-bottom  : 0px;
  background-color: #e2e2e2;
  border-radius   : 0px;
  cursor          : pointer;

  min-width: 35px;
}

.footerExit {
  margin-top: 10px;

  display        : flex;
  flex-direction : row;
  align-items    : flex-end;
  justify-content: space-between;
}

.buttonGreen {
  background-color: #83fa33d5 !important;
}

.questionSpan {
  display      : block;
  width        : 800px;
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

.correct {
  font-size       : 10px;
  border-radius   : 50px;
  text-align      : center;
  width           : 70px;
  background-color: #bdffb7;
  color           : #02aa10;
  padding         : 2px 0;
  font-weight     : bolder;
}

.incorrect {
  font-size       : 10px;
  border-radius   : 50px;
  text-align      : center;
  width           : 70px;
  background-color: #ffd9d9;
  color           : #aa0202;
  padding         : 2px 0;
  font-weight     : bolder;
}

.popup {
  position: fixed;
  top     : 10px;
  right   : 10px;
  width   : 100%;
  height  : 100%;
  z-index : 9999;

  width : 500px;
  height: fit-content;

  background-color: #ffceb78f;

  padding: 20px;

  border-radius: 10px;
  border       : 1px solid #ff0000;
}