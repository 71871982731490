.main {
  margin-top: 20px;
}

.ans {
  display    : flex;
  align-items: center;

  column-gap: 16px;
  width     : 100%;
  height    : 100%;
}

.keyAns {
  min-width : 30px;
  min-height: 30px;
  width     : 30px;
  height    : 30px;

  border-radius   : 50%;
  background-color: rgb(236, 236, 236);

  display        : flex;
  align-items    : center;
  justify-content: center;
}

.nextButton {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  padding        : 8px 20px;
  height         : 60px;
}

.warning {
  background-color: rgb(255, 219, 205);
  font-size       : 12px;
  padding         : 2px 10px;
  font-weight     : 700;
  border-radius   : 50px;

  display: inline-block;
}

.headerQuestionNumber {
  display    : flex;
  align-items: center;
  padding    : 4px 20px;
  column-gap : 20px;
}

.progress {
  position: fixed;
  top     : 20px;
  right   : 20px;

  width : 250px;

  z-index: 999999999999;

  transition: all 0.5s ease-in-out;
}